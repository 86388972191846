import { Component } from '@angular/core';
import { GeneralService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

declare var $

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.scss']
})
export class MyOrdersComponent {
    loginDet: any;
    OrderedProductList: any;
    HomeUrl: any;
    arr: any = [];
    schoolpro: any;
    schooldata1: any;
    loginDetails: any;
    ww: any; orderDetails: any;
    EnquriesProductList: any;
    productDetails: any;
    selectedTab: 'orders' | 'enquiries' = 'orders';
    token: any;
    id: any;
    constructor(public generalService: GeneralService, public fb: FormBuilder, public http: HttpClient, private route: ActivatedRoute,
        public router: Router) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        
    }
    ngOnInit(): void {

        this.getOrders();
        this.GetEnquires();
        this.route.queryParams.subscribe(params => {
            // Set selectedTab based on the query parameter
            this.selectedTab = params['selectedTab'] || 'orders';
        });
}
    
    getOrders() {      
       
            var UploadFile = new FormData()
            UploadFile.append("Param", this.loginDetails[0].RegId);
            var url ="api/NovusChem/Get_Orders_basedon_UserID";
        this.generalService.PostData(url, UploadFile).then(data => {                

                    this.OrderedProductList = data;
                },
                (err) => {
                   
                });
       
    }
    GetEnquires() {
      

        var UploadFile = new FormData()
        UploadFile.append("Param1", this.loginDetails[0].RegId);

        var url = "api/NovusChem/Get_MyEnquriesbasedonUID";
        this.generalService.PostData(url, UploadFile).then(data => {
          
            this.EnquriesProductList = data;

            this
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait a minute', 'error')

        })
    }
   

    navigateToInvoice(orderid:any) {
      
        this.router.navigate(['/Invoice/' + orderid]);
    }

    

   
}
