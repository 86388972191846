<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Checkout</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:white;"><a routerLink="/Cart">Add to cart /</a></li>
                <li class="breadcrumb-item" style="color:white;">Checkout</li>
            </ul>
        </div>
    </nav>
   
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <br />
               </div>
            <div class="container">
                <div style="text-align:right">
                    <button class="btn btn-success btn-block" style="padding: 5px; width: 19%; background: #2b7689; margin-top: 30px;" (click)="GotoAddress()">
                        Add Address <i class="fa fa-angle-right"></i>
                    </button>
                </div>
                <div class="row" *ngIf="addressDetails && addressDetails.length > 0">
                    <!-- Billing Address -->
                    <div class="col-lg-4">
                        <label for="billingAddress" class="text-black font-weight-bold">Billing Address</label>
                        <ng-container *ngFor="let item of addressDetails">
                            <div class="row">
                                <div class="col-lg-1 col-1">
                                    <input type="radio" *ngIf="item.ACID === 1" name="billingAddressCheckbox"
                                           (change)="getSelectedbillingAddress(item.AID, $event.target.checked, item.ACID)"
                                           style="width: 18px; height: 18px;margin-top:8px;" />
                                </div>
                                <div class="col-lg-11 col-11">
                                    <div *ngIf="item.ACID === 1" class="card">
                                        <span>
                                            {{ item.Customername }}, {{ item.Mobileno }},<br />{{ item.HNo }}, {{ item.Landmark }},
                                            {{ item.City }},<br />{{ item.State }}, {{ item.Address }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div *ngIf="isBillingAddressSelected && !isDeliveryAddressSelected">
                            <input type="checkbox" id="useBillingAddressCheckbox" [(ngModel)]="useBillingAddress"
                                   (change)="useBillingAddressForDelivery($event.target.checked)"
                                   [checked]="useBillingAddress" />
                            <label for="useBillingAddressCheckbox"> &nbsp; Use billing address for delivery</label>
                        </div>
                    </div>

                    <!-- Delivery Address -->
                    <div class="col-lg-4">
                        <label for="deliveryAddress" class="text-black font-weight-bold">Delivery Address</label>
                        <ng-container *ngFor="let item of addressDetails">

                            <div class="row">
                                <div class="col-lg-1 col-1">
                                    <input type="radio" name="deliveryAddressCheckbox" *ngIf="item.ACID === 2"
                                           (change)="getSelectedDeliveryAddress(item.AID, $event.target.checked, item.ACID)"
                                           style="width: 18px; height: 18px;margin-top:8px;" />
                                </div>
                                <div class="col-lg-11 col-11">
                                    <div *ngIf="item.ACID === 2" class="card">
                                        <span>
                                                {{ item.Customername }}, {{ item.Mobileno }},<br />{{ item.HNo }}, {{ item.Landmark }},
                                            {{ item.City }},<br />{{ item.State }}, {{ item.Address }}
                                        </span>
                                    </div>
                                </div>
                            </div>


                        
                        </ng-container>

                        <div *ngIf="isDeliveryAddressSelected && !isBillingAddressSelected">
                            <input type="checkbox" id="useDeliveryAddressCheckbox" [(ngModel)]="useDeliveryAddress"
                                   (change)="useDeliveryAddressForBilling($event.target.checked)"
                                   [checked]="useDeliveryAddress" />
                            <label for="useDeliveryAddressCheckbox"> &nbsp; Use delivery address for billing</label>
                        </div>
                    </div>
                                                                                          
                    <div class="col-lg-4">
                        <label></label>
                        <div class="row"> 
                            <div class="card pricede" style="width:100%;float:right">
                                <h6 style="text-align:center;color:#f4880d">Price Details</h6>
                                <hr />
                                <div class="row">
                                    <div class="col-lg-6 col-xs-6"><div>Delivery Charges</div></div>
                                    <div class="col-lg-6 col-xs-6"><h6>--</h6></div><p></p>
                                    <div class="col-lg-6 col-xs-6"><div>Total Payable:</div></div>
                                    <div class="col-lg-6 col-xs-6"><h6>₹{{Grandtotal.toFixed(2)}}</h6></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>





        <div class="row" *ngIf="address1 || address2">
            <div class="col-lg-4">
                <button class="btn btn-success btn-block" style="padding: 5px; width: 58%; background: #2b7689; margin-top: 30px;" (click)="PlaceOrder1()">
                    CONTINUE <i class="fa fa-angle-right"></i>
                </button>
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-4"></div>
        </div>
    </div>
    </div>

