<div id="about" class="about-area ptb-100">
    <div id="loader" style="display: none;">
        <div class="spinner"></div>
    </div>

    <nav aria-label="breadcrumb" class="breadcrumb" style="background-color:#ffecec;">
        <div class="container">
            <ul>

                <li style="cursor:pointer;">
                    <a href="/" style="color:#d03737;"> <i class="fas fa-home"></i> Home >></a>
                </li>



                <li class="" style="color:#d03737;">SignUp</li>
            </ul>


        </div>

    </nav>
    <br />

    <div class="container">
        <h3 style="color:#d03737">Please select you are.?</h3><br />
        <div class="row">
            <div class="col-lg-6">
                <img src="../../../assets/Images/customer-service.png" style="width: 189px;cursor:pointer" routerLink="/Registration" /><br /><br />
                <b><h4 style="color:#d03737;margin-left: 44px;cursor:pointer" routerLink="/Registration">Customer</h4></b>
            </div>
            <div class="col-lg-6">
                <img src="../../../assets/Images/download.png" style="width: 200px;cursor:pointer" routerLink="/DistributorReg" /><br />
                <b><h4 style="color:#d03737;margin-left: 44px;cursor:pointer"routerLink="/DistributorReg" >Distributor</h4></b>
            </div>
        </div>
    </div>
</div>
