<div id="about" class="about-area ptb-100"style="padding-bottom:0">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <div>Careers</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:white;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:white;">Careers</li>
            </ul>
        </div>
    </nav>
  
        <div class="header">
            <div class="header-text">
                <div class="apply_box">



                

                    <form form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                        <div class="form_container">
                            <div class="form_control">
                                <label>Name</label>
                                <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name">
                                <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                    <p *ngIf="name.errors.required">Name is required.</p>
                                    <p *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</p>
                                    <p *ngIf="name.errors.maxlength">Name is maximum 50 character.</p>

                                </div>
                            </div>

                            <div class="form_control">
                                <label>Phone Number</label>
                                <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number">
                                <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                            </div>
                            <div class="form_control">
                                <label>Email</label>
                                <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email">
                                <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                            </div>

                            <div class="form_control">
                                <label>Job Role</label>
                                <input required ngModel name="selectedJobRole" type="text" #selectedJobRole="ngModel" class="form-control" id="email">
                                <div class="alert alert-danger" *ngIf="selectedJobRole.touched && !selectedJobRole.valid">Job Role is required.</div>
                            </div>
                            <!--<div class="form_control">
                                <label for="job">Job Role</label>
                                <select required ngModel name="selectedJobRole" id="selectedJobRole" class="form-control">
                                    <option value="">Select Job Role</option>
                                    <option value="Pharma">Pharma</option>
                                    <option value="Fronted Devloper">Fronted Devloper</option>
                                    <option value="Backend Devloper">Backend Devloper</option>
                                    <option value="Full stack Devloper">Full stack Devloper</option>
                                    <option value="UI IX Designer">UI IX Designer</option>
                                    <option value="Tester">Tester</option>
                                </select>
                           
                            </div>-->

                            <div class="form_control">
                                <label for="date">Date</label>
                                <input type="date" placeholder="select date" ngModel name="todayDate" class="form-control" id="todayDate">
                          
                            </div>
                            <div class="form_control">
                                <label for="Upload">Upload Your CV</label>
                                <input type="file" id="upload" name="upload" (change)="onFileSelected($event)" accept=".pdf,.doc,.docx">
                            </div>
                            <div class="textarea_control">
                                <label>Address</label>
                                <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="4" class="form-control"></textarea>
                                <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                            </div>
                        </div>
                        <div class="button_container">
                            <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Send Message</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    
    </div>
