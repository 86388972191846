




<!--this code use for google analyticals-->
<div id="about" class="about-area ptb-100"><nav aria-label="breadcrumb" class="breadcrumb">
    <div class="container">
        <ul>
            <div>My Order</div>
            <li style="cursor:pointer;">
                <a href="/" style="color:white;">
                    <i class="fas fa-home"></i> Home /
                </a>
            </li>
            <li class="breadcrumb-item" style="color:white;">My Order</li>
        </ul>
    </div>
</nav>
    
                                           <div class="container">
                                               <div>
                                                   <ul class="nav nav-tabs">
                                                       <li class="nav-item" (click)="selectedTab = 'orders'">
                                                           <a class="nav-link" [class.active]="selectedTab === 'orders'" [ngClass]="'pointer-cursor'">My Orders</a>
                                                       </li>
                                                       <li class="nav-item" (click)="selectedTab = 'enquiries'">
                                                           <a class="nav-link" [class.active]="selectedTab === 'enquiries'" [ngClass]="'pointer-cursor'">My Enquiries</a>
                                                       </li>
                                                       <!--<li class="nav-item" (click)="selectedTab = 'favrt'">
                                                           <a class="nav-link" [class.active]="selectedTab === 'enquiries'" [ngClass]="'pointer-cursor'">My Favourites</a>
                                                       </li>-->
                                                   </ul>
                                               </div>

                                               <br />
                                               <div *ngIf="selectedTab === 'orders'">
                                                   <div class="row">
                                                       <div class="col-lg-4 col-md-2 col-sm-2 col-xs-2" *ngFor="let item1 of OrderedProductList;let i=index">
                                                           <br />
                                                           <div class="card" [ngClass]="{'first-card': i === 0}" >
                                                               <div class="row">
                                                                   <div class="col-lg-5  col-xs-6">
                                                                       <div class="card_left"> {{item1.OrderID}}   </div>
                                                                   </div>
                                                                   <div class="col-lg-7 col-xs-6">
                                                                       <div class="card_right"> {{item1.CreatedDate | date:'dd-MMM-yyyy hh:mm a'}}</div>
                                                                   </div>
                                                               </div>

                                                               <div class="row">
                                                                   <div class="col-lg-5  col-xs-6">
                                                                       <div class="card_left" style="color:red">{{item1.StatusName}}</div>
                                                                   </div>
                                                                   <div class="col-lg-7  col-xs-6">
                                                                       <div class="card_right"> Order Amount : {{item1.TotalPrice}}/-  </div>
                                                                   </div>
                                                               </div>
                                                               <div class="row">
                                                                   <div class="col-lg-5  col-xs-6">
                                                                      
                                                                   </div>
                                                                   <div class="col-lg-7  col-xs-6"*ngIf="item1.StatusName=='Completed'" (click)="navigateToInvoice(item1.OrderID)">
                                                                       <button class="btn btn-success btn-block" style="padding: 5px; width: 100%; background: #2b7689;">
                                                                           Invoice <i class="fa fa-angle-right"></i>
                                                                       </button>
                                                                       <!--<div class="card_right"> Invoice  </div>-->
                                                                   </div>
                                                               </div>
                                                           </div>

                                                       </div>
                                                   </div>
                                               </div>
                                               <div *ngIf="selectedTab === 'enquiries'">

                                                   <div class="row">
                                                       <div class="col-lg-4 col-md-2 col-sm-2 col-xs-2" *ngFor="let item1 of EnquriesProductList;let i=index">
                                                           <br />
                                                           <div class="card" >
                                                               <div class="row">
                                                                   <div class="col-lg-4  col-xs-6">
                                                                       <div class="card_left">  Enquiry ID : {{item1.Enq_Id}}   </div>
                                                                   </div>
                                                                   <div class="col-lg-8  col-xs-6">
                                                                       <div class="card_right"> {{item1.Enq_PostedOn | date:'dd-MMM-yyyy hh:mm a'}}</div>
                                                                   </div>
                                                               </div>

                                                               <div class="row">
                                                                   <div class="col-lg-6  col-xs-6">
                                                                       <div class="card_left">{{item1.ProductName}}</div>
                                                                   </div>
                                                                   <div class="col-lg-6  col-xs-2">
                                                                       <div class="card_right"> CAT No : {{item1.Catalog_No}}  </div>
                                                                   </div>
                                                                   <div class="row">
                                                                       <div class="col-lg-6  col-xs-2">
                                                                           <div class="card_left"> QTY : {{item1.Quantity}} {{item1.UOM}}  </div>
                                                                       </div>
                                                                       <!--<div class="col-lg-6  col-xs-2">
                                <div class="card_right"> UOM : {{item1.UOM}}  </div>
                            </div>-->
                                                                       <div class="col-lg-6  col-xs-2">
                                                                           <div class="card_right"> Status : {{item1.Tabstatus}}  </div>
                                                                       </div>
                                                                   </div>
                                                                   <div class="row">
                                                                       <div class="col-lg-6  col-xs-2">
                                                                           <div class="card_left"> Price : {{item1.Price}}   </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                       </div>
                                                   </div>
                                               </div>

                                               <!--<div *ngIf="selectedTab === 'favrt' && id==1">

                                                   <div class="row">
                                                       <div class="col-lg-4 col-md-2 col-sm-2 col-xs-2" *ngFor="let item1 of EnquriesProductList;let i=index">
                                                           <br />
                                                           <div class="card">
                                                               <div class="row">
                                                                   <div class="col-lg-4  col-xs-6">
                                                                       <div class="card_left">  Enquiry ID : {{item1.ENQ_ID}}   </div>
                                                                   </div>
                                                                   <div class="col-lg-8  col-xs-6">
                                                                       <div class="card_right"> {{item1.Enq_PostedOn | date:'dd-MMM-yyyy hh:mm a'}}</div>
                                                                   </div>
                                                               </div>

                                                               <div class="row">
                                                                   <div class="col-lg-6  col-xs-6">
                                                                       <div class="card_left">{{item1.Name}}</div>
                                                                   </div>
                                                                   <div class="col-lg-6  col-xs-2">
                                                                       <div class="card_right"> CAT No : {{item1.Catalogue_Number}}  </div>
                                                                   </div>
                                                                   <div class="row">
                                                                       <div class="col-lg-6  col-xs-2">
                                                                           <div class="card_left"> QTY : {{item1.Quantity}} {{item1.UOM}}  </div>
                                                                       </div>-->
                                                                       <!--<div class="col-lg-6  col-xs-2">
                                <div class="card_right"> UOM : {{item1.UOM}}  </div>
                            </div>-->
                                                                       <!--<div class="col-lg-6  col-xs-2">
                                                                           <div class="card_left"> Status : {{item1.Tabstatus}}  </div>
                                                                       </div>
                                                                   </div>
                                                                   <div class="row">
                                                                       <div class="col-lg-6  col-xs-2">
                                                                           <div class="card_left"> Price : {{item1.Price}}   </div>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>

                                                       </div>
                                                   </div>
                                               </div>-->
                                           </div>


    </div>












