<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb" style="background-color:#ffecec;">
        <div class="container">
            <ul>
                <div style="color:#d03737;">How To Order</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:#d03737;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:#d03737;">How To Order</li>
            </ul>
        </div>
    </nav>

    <div class="container pt-70">
        <div class="row">
            <div class="col-lg-4">
                <img class="image" src="assets/img/home/register(2).png" alt="novus" />
            </div>
            <div class="col-lg-8">
                <h4>Company/Institute Registration</h4>
                <ul>
                    <li>
                        To initiate the order placement process, your company or institute must first be registered in our billing system.
                    </li>
                    <li>
                        To accomplish this, please provide us with valid proof of your company's credentials, such as GST details and PAN information.
                    </li>
                    <li>
                        Once our accounts team confirms and approves your registration, you will gain access to place orders.
                    </li>
                    <li>
                        Please note that orders can only be placed by customers with a valid GST number.
                    </li>
                </ul>
            </div>
            <div class="12">
                <h4>
                    Order Submission Requirements
                </h4>
                <ul>
                    <li>
                        When submitting a purchase order, it must be on your company's letterhead or in an official purchase order format.
                    </li>
                    <li>
                        Your purchase order should include essential details such as the "Catalogue number," "Cas number," "Price," and "Quantity," clearly specified.
                    </li>
                </ul>
            </div>
        </div>
            <div class="row mt-5">
            <div class="col-lg-6">
                <div class="card">
                    <h4>
                        Payment Terms
                    </h4>
                    <p>Payment terms for orders are typically in advance, and customers are required to submit the payment receipt along with their purchase order copy. For long-term or repeat customers, specific payment terms can be negotiated and finalized by our company</p>
                </div>
                </div>
            <div class="col-lg-6">
                <div class="card">
                    <h4>
                        Get the Best Offers
                    </h4>
                    <p>To avail of the best offers from our company, simply add the desired products to your cart and submit them to the "Get Best Offers" section on our platform.</p>
                </div>
            </div>
               
               
            </div>
        <div class="row mt-5">
            <div class="col-lg-8">
                <h4>
                    Ordering through Distributors
                </h4>
                <p>
                    If you are considering placing an order through distributors, we recommend using a "Novus Authorized Distributor" in your respective region.
                    To identify your authorized distributor, please contact us at <a href="mailto:info@novuschems.com" target="_blank">info@novuschems.com</a>.
                </p>
            </div>
            <div class="col-lg-4 "></div>
        </div>
        <div class="row mt-5">
            
            <div class="col-lg-12">
                <h4>
                    Become a Novus Authorized Distributor (NAD)
                </h4>
                <p>If you are interested in becoming a "Novus Authorized Distributor (NAD)," kindly get in touch with us via email at <a href="mailto:sales@novuschems.com" target="_blank">sales@novuschems.com</a> or call us at <a href="tel:9033360776" target="_blank">+91 9033360776.</a></p>
            </div>
        </div>
    </div>
  </div>
