
<br />    <br />
          <div id="about" class="about-area ptb-100">
              <br />

              <div class="container mobilescrn" *ngIf="addid!=undefined">
                  <div class="row">
                      <h3 class="a_heading headingg">Add Address</h3>
                      <br />
                  
                   
                            
                              <div class="col-lg-3 ">
                                  <label for="category">Address Type<span style="color:red">*</span></label>
                                  <div class="add_input">
                                      <p-autoComplete [(ngModel)]="Categoryname" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                                      field="Categoryname" [dropdown]="true" [size]="90"
                                                      placeholder="Select Address type" [minLength]="1"
                                                      [style]="{'color': 'black'}"
                                                      [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                                      </p-autoComplete>
                                  </div>
                                  <small *ngIf="form.controls.Categoryname.touched && form.controls.Categoryname.errors?.required"
                                         class="p-error" style="color:red">Address type is required.</small>
                              </div>
                        

                      
                              <div class="col-lg-3">
                                  <label>User Name<span style="color:red">*</span></label>
                                  <input type="text" placeholder="Enter Name" class="add_input" [(ngModel)]="Customername" />
                              </div>
                              <div class="col-lg-3 ">
                                  <label>Mobile number<span style="color:red">*</span></label>
                                  <input class="add_input" type="text" maxlength="10" [(ngModel)]="Mobileno" placeholder="Enter Mobile No" />
                              </div>
                              <div class="col-lg-3">
                                  <label>Flat, House no<span style="color:red">*</span></label>
                                  <input class="add_input" type="text" placeholder="Enter HNo/Flat No" [(ngModel)]="HNo" />
                              </div>
                              <div class="col-lg-3 ">
                                  <label>Landmark<span style="color:red">*</span></label>
                                  <input class="add_input" type="text" placeholder="Enter Landmark" [(ngModel)]="Landmark" />
                              </div>
                       

                       
                              <div class="col-lg-3">
                                  <label>Town/City<span style="color:red">*</span></label>
                                  <input class="add_input" type="text" placeholder="Enter City" [(ngModel)]="City" />
                              </div>
                              <div class="col-lg-3 ">
                                  <label>State Name<span style="color:red;">*</span></label>
                                  <div class="form-group">
                                      <p-autoComplete [suggestions]="statelist" (completeMethod)="filterGroupsSingle7($event)" dataKey='Id' field="StateName" [(ngModel)]="StateName" [dropdown]="true" [size]="50"
                                                      placeholder="Search with State " [minLength]="1" [forceSelection]="true">
                                          <ng-template let-autofillCustomers pTemplate="item">
                                              <div class="card pb-4 ps-5 drop" style="height:15px; border:none;padding-left:10px;">
                                                  {{autofillCustomers.StateName}}
                                              </div>
                                          </ng-template>
                                      </p-autoComplete>
                                  </div>
                              </div>
                              <div class="col-lg-3 ">
                                  <label>Pincode<span style="color:red">*</span></label>
                                  <input class="add_input" placeholder="Enter Pincode" type="tel" pattern="[0-9]*" maxlength="6" [(ngModel)]="Pincode" />
                              </div>
                              <div class="col-lg-12">
                                  <label>Address</label>
                                  <textarea class="add_input" type="text" placeholder="Enter Address" [(ngModel)]="Address"></textarea>
                              </div>
                          </div>

                  <div class="row">
                      <div class="col-lg-8 "></div>
                          <div class="col-lg-2 ">
                              <button class="btn-danger btn" (click)="Back()">Cancel</button>
                          </div>
                          <div class="col-lg-2 " *ngIf="addid!=undefined">
                              <button class="btn-success btn" (click)="UpdateAdderss(Customername, Mobileno, HNo, Address, Landmark, City, StateName, Pincode,Categoryname,ACID)">Update</button>
                          </div>
                      </div>
                  </div>
                      
                  
         

              <div class="container mobilescrn" *ngIf="addid==undefined">
                  <div class="row">
                      <h3 class="a_heading headingg">Add Address</h3>
                      <br />



                      <div class="col-lg-3">
                          <label for="category">Address Type<span style="color:red">*</span></label>
                          <div class="add_input">
                              <p-autoComplete [(ngModel)]="Categoryname" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                                              field="Categoryname" [dropdown]="true" [size]="90"
                                              placeholder="Select Address type" [minLength]="1"
                                              [style]="{'color': 'black'}"
                                              [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':'rgba(0, 0, 0, 0.24) 0px 3px 8px'}">
                              </p-autoComplete>
                          </div>
                          <small *ngIf="form.controls.Categoryname.touched && form.controls.Categoryname.errors?.required"
                                 class="p-error" style="color:red">Address type is required.</small>
                      </div>



                      <div class="col-lg-3 ">
                          <label>Full name (First and Last name)<span style="color:red">*</span></label>
                          <input type="text" placeholder="Enter Name" class="add_input" [(ngModel)]="Customername" />
                      </div>
                      <div class="col-lg-3 ">
                          <label>Mobile number<span style="color:red">*</span></label>
                          <input class="add_input" type="text" maxlength="10" [(ngModel)]="Mobileno" placeholder="Enter Mobile No" />
                      </div>
                      <div class="col-lg-3 ">
                          <label>Flat, House no <span style="color:red">*</span></label>
                          <input class="add_input" type="text" placeholder="Enter HNo/Flat No" [(ngModel)]="HNos" />
                      </div>
                      <div class="col-lg-3 ">
                          <label>Landmark<span style="color:red">*</span></label>
                          <input class="add_input" type="text" placeholder="Enter Landmark" [(ngModel)]="Landmarks" />
                      </div>



                      <div class="col-lg-3 ">
                          <label>Town/City<span style="color:red">*</span></label>
                          <input class="add_input" type="text" placeholder="Enter City" [(ngModel)]="Citys" />
                      </div>
                      <div class="col-lg-3 ">
                          <label>State Name<span style="color:red;">*</span></label>
                          <div class="form-group">
                              <p-autoComplete [suggestions]="statelist" (completeMethod)="filterGroupsSingle7($event)" dataKey='Id' field="StateName" [(ngModel)]="StateName" [dropdown]="true" [size]="50"
                                              placeholder="Search with State " [minLength]="1" [forceSelection]="true">
                                  <ng-template let-autofillCustomers pTemplate="item">
                                      <div class="card pb-4 ps-5 drop" style="height:15px; border:none;padding-left:10px;">
                                          {{autofillCustomers.StateName}}
                                      </div>
                                  </ng-template>
                              </p-autoComplete>
                          </div>
                      </div>
                      <div class="col-lg-3 ">
                          <label>Pincode<span style="color:red">*</span></label>
                          <input class="add_input" placeholder="Enter Pincode" type="tel" pattern="[0-9]*" maxlength="6" [(ngModel)]="Pincodes" />
                      </div>
                      <div class="col-lg-12">
                          <label>Address</label>
                          <textarea class="add_input" type="text" placeholder="Enter Address" [(ngModel)]="Addresss"></textarea>
                      </div>

                  </div>
                  <div class="row">
                      <div class="col-lg-8 "></div>
                          <div class="col-lg-2 ">
                              <button class="btn-danger btn" (click)="Back()">Cancel</button>
                          </div>
                          <div class="col-lg-2 " *ngIf="addid==undefined">
                              <button class="btn-success btn" (click)="InsertAdderss(Customername, Mobileno, HNos, Addresss, Landmarks, Citys, StateName, Pincodes, Categoryname,ACID)">Add</button>
                          </div>
                      </div>

                      <div class="col-lg-3"></div>
                  </div>
              </div>


