import { Component } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GeneralService } from '../../Services/generalservice.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent {

     public form: FormGroup;
    arr: any = [];
    districtdata: any = [];
    deptList: any;
    flag: any;
    Statedata: any = [];
    profiledata: any;
    Citydata: any = [];
    Name: any;
    mobile: any;
    HNo: any;
    Address: any;
    Landmark: any;
    City: any;
    State: any;
    Pincode: any;
    deptList1: any;
    deptList2: any; bloodgroupdata: any = [];
    //deptList3: any;
    subDropdownStyle: { [key: string]: string } = {};
    isMouseOverSubDropdown: boolean = false;
    showDropdownContent = false;
    selectedBloodgroup: string = '';
    selectedGender: string | null = null;
    LoginDetails1: any; LoginDetails: any;
    selectedpast: string;
    Lastdonatedate: any;
    Status: boolean = true;
    Mobile: any;
    RegID: any;
    logindata: boolean;
    schooldata: any;
    subtasklist: any;
    ButtonText: any;
    selectedOption: any;
    ID: any;
    regid: any;
    addid: any;
    datafilter: any;
    users: any;
    //category: any[] = [];
    deptList3: any[] = [];
    Categoryname: any;
    Names: any; mobiles: any; HNos: any; Addresss: any; Landmarks: any; Citys: any; States: any; Pincodes: any; selectedAddressType: string; //Categoryname: any;
    category: any;
    ACID: any;
    Customername: any;
    Mobileno: any;
    statelist: any; subtasks: any; StateName: any;
    constructor(public genralservice: GeneralService, private activatedRoute: ActivatedRoute, public http: HttpClient, public fb: FormBuilder, public router: Router,) {

        debugger
        localStorage.removeItem("keepsignin");
        this.LoginDetails1 = localStorage.getItem("LoginDetails");
        this.LoginDetails = JSON.parse(this.LoginDetails1);
       
        this.addid = this.activatedRoute.snapshot.paramMap.get("addid")

        if (this.LoginDetails != null) {
            this.logindata = true;
            this.regid = this.LoginDetails[0].RegId
            this.Name = this.LoginDetails[0].UserName
            this.mobile = this.LoginDetails[0].Phone1
            
            //this.State = this.LoginDetails[0].State
            //this.Pincode = this.LoginDetails[0].Pincode
            //this.Landmark = this.LoginDetails[0].Landmark
        } else {
            this.logindata = false; 
        }
        this.form = this.fb.group({
            Categoryname: ['', Validators.required],
            StateName: ['',]// Set up form control for Categoryname with required validation
        });
    }

    ngOnInit(): void {
       
        //this.getregdetails();
        this.getaddresscat();
        this.GetState();
    }




    getregdetails() {
       
        this.arr = [];
        this.arr.push({});
        var UploadFile = new FormData();
       // UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Param1", this.LoginDetails[0].RegId);
        UploadFile.append("Param2", '1');
        var url = "api/Azole/Addressfilterbasedonuid";
        this.genralservice.PostData(url, UploadFile).then(data => {
            
            this.schooldata = data;

            // Convert this.addid to a number
            const addidNumber = parseInt(this.addid);

            // Filter the records based on addid
            this.datafilter = this.schooldata.filter((item: any) => item.AID === addidNumber);

            // Check if any record is found
            if (this.datafilter.length > 0) {
                this.HNo = this.datafilter[0].HNo;
                this.Address = this.datafilter[0].Address;
                this.Landmark = this.datafilter[0].Landmark;
                this.City = this.datafilter[0].City;
                this.State = this.datafilter[0].State;
                this.Pincode = this.datafilter[0].Pincode;
                this.Categoryname = this.datafilter[0].Categoryname;
                this.Customername = this.datafilter[0].Customername;
                this.Mobileno = this.datafilter[0].Mobileno;
                this.ACID = this.datafilter[0].ACID;
               // this.StateName = this.datafilter[0].StateName;
                this.StateName = {
                    StateName: this.datafilter[0].StateName,
                    State: this.datafilter[0].State // Assuming GSTID is available in the response
                };

            } else {
                // Handle case when no records are found with the given AID
                // You might want to show a message or handle it according to your application logic
            }
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Some thing went Wrong', 'error');
        });
    }

    GetState() {
        
        // Call Gettoken and await its completion
        

            
            this.arr = [];
            this.arr.push({
               
            });

            var UploadFile = new FormData();
            UploadFile.append("Param", JSON.stringify(this.arr));
            UploadFile.append("Flag", '4');

            var url = "api/NovusChem/Sp_crud_statemaster";
            
            // Use HttpHeaders in the request
            this.genralservice.PostData(url, UploadFile).then(data => {



                this.subtasks = data;
            },
                err => {
                    //this.genralservice.ShowAlert('ERROR', 'Something went wrong, please try again later', 'error');
                });
        
    }

    

    filterGroupsSingle7(event: any) {
        
        // Your existing autocomplete filter logic goes here
        let filtered: any = [];
        let query = event.query;
        {
            this.statelist = [];
            for (let i = 0; i < this.subtasks.length; i++) {
                let type = this.subtasks[i];
                if (type.StateName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                }
            }
           
            this.statelist = filtered;
        }

        // Update GSTID when a value is selected from the autocomplete dropdown

    }



    InsertAdderss(Customername: any, Mobileno: any, HNo: any, Address: any, Landmark: any, City: any, StateName: any, Pincode: any, Categoryname: any,ACID:any) {
        debugger
        if (!Categoryname) {
            this.genralservice.ShowAlert('Warning', 'Category name is missing.', 'Warning');
            return;
        }

        if (!Categoryname.ACID) {
            this.genralservice.ShowAlert('Warning', 'ACID is missing.', 'Warning');
            return;
        }

        if (!Customername) {
            this.genralservice.ShowAlert('Warning', 'Full name is missing.', 'Warning');
            return;
        }

        if (!Mobileno) {
            this.genralservice.ShowAlert('Warning', 'Mobile number is missing.', 'Warning');
            return;
        }

        if (!HNo) {
            this.genralservice.ShowAlert('Warning', 'House number is missing.', 'Warning');
            return;
        }

        if (!Landmark) {
            this.genralservice.ShowAlert('Warning', 'Landmark is missing.', 'Warning');
            return;
        }

        if (!City) {
            this.genralservice.ShowAlert('Warning', 'City is missing.', 'Warning');
            return;
        }

        if (!this.StateName) {
            this.genralservice.ShowAlert('Warning', 'State is missing.', 'Warning');
            return;
        }

        if (!Pincode) {
            this.genralservice.ShowAlert('Warning', 'Pincode is missing.', 'Warning');
            return;
        }

        
        this.arr = [{
           
            UID: this.regid,
            Customername: Customername,
            Mobileno: Mobileno,
            HNo: HNo,
            Address: Address,
            Landmark: Landmark,
            City: City,
            State: this.StateName.StateName,
            Pincode: Pincode,
            CategoryID: Categoryname.ACID
        }];       
        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));

        uploadfile.append("Flag", '1');
        var url = 'api/NovusChem/Address_Master';
        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data != "") {
                debugger;
                if (this.flag == 1) {
                    //this.genralservice.ShowAlert("ERROR", 'You have successfully signed in as a Address form', 'error');
                } else {
                    let categoryType = 'Delivery/Shipping'; // Default category type

                    // Check if 'Categoryname' is set and 'ACID' is 1 for Billing, otherwise, it's Shipping
                    if (this.Categoryname && this.Categoryname.ACID === 1) {
                        categoryType = 'Billing';
                    }

                    this.genralservice.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', `You have successfully added the ${categoryType} Address`, '');
                    this.router.navigate(['/Useraddress']);





                }
            }
        });
    }
    UpdateAdderss(Customername: any, Mobileno: any, HNo: any, Address: any, Landmark: any, City: any, StateName: any, Pincode: any, Categoryname: any, ACID:any) {
        
        const state = this.StateName ? this.StateName.StateName : null;
        if (!Categoryname || !ACID) {
            this.genralservice.ShowAlert('Warning', 'Address type is missing.', 'Warning');
            return;
        }
        this.arr = [{
            // SID: this.selectedOption,
            AID: this.addid,
            UID: this.regid,
            Customername: Customername,
            Mobileno: Mobileno,
            HNo: HNo,
            Address: Address,
            Landmark: Landmark,
            City: City,
            State: state,
            Pincode: Pincode,
            CategoryID: ACID

        }];

        var uploadfile = new FormData();
        uploadfile.append("Param", JSON.stringify(this.arr));
        uploadfile.append("Flag", '2');

        var url = 'api/NovusChem/Address_Master';

        this.genralservice.PostData(url, uploadfile).then(data => {
            if (data == "SUCCESS") {
              
                if (this.flag == 2) {
                    //this.genralservice.ShowAlert("ERROR", 'You have successfully signed in as a Address form', 'error');
                } else {
                    this.genralservice.Alertsuccess('<img src = "../../../assets/icons/icons8-success.gif" />', 'Address updated successfully ✔️', '');

                  
                    this.router.navigate(['/Useraddress']);


                }
            }
        });
    }

    ShowAlert(type: string, message: string, icon: string) {
        const alertElement = document.createElement('div');
        alertElement.classList.add('custom-alert', type);

        if (icon === 'tick') {
            alertElement.innerHTML = `<div class="tick-mark">✔️</div><div>${message}</div>`;
        } else {
            alertElement.innerText = message;
        }

        document.body.appendChild(alertElement);

        setTimeout(() => {
            alertElement.remove();
        }, 3000); // Remove alert after 3 seconds
    }
    Back() {
        this.router.navigate(['/Useraddress']);
    }
    ngAfterViewInit() {
     document.getElementById('preloader').classList.add('hide');
    }
    getaddresscat() {
        this.category = []; // Clear the category array
        var UploadFile = new FormData();
        UploadFile.append("Param1", this.LoginDetails[0].RegId);
        UploadFile.append("Param2", '3');
        var url = "api/NovusChem/Addressfilterbasedonuid";
        this.genralservice.PostData(url, UploadFile).then(data => {
            this.category = data;
        }, err => {
            this.genralservice.ShowAlert('ERROR', 'Something went wrong', 'error');
        });
    }

    // Assuming you filter deptList3 based on user input
    searchCont3(event) {
        let filtered: any = [];
        let query = event.query;
        {
            this.deptList3 = [];
            for (let i = 0; i < this.category.length; i++) {
                let type = this.category[i];
                if (type.Categoryname.toLowerCase().indexOf(query.toLowerCase()) == 0) {
                    filtered.push(type);
                    //   this.ButtonText = 'Add';
                }
            }
            this.deptList3 = filtered;
        }
    }
}




