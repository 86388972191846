<div id="about" class="about-area ptb-100" style="padding-bottom:0">
    <nav aria-label="breadcrumb" class="breadcrumb" style="background-color:#ffecec;">
        <div class="container" style="margin-top:10px">
            <ul>
                <div style="color:#d03737;">Supplier</div>
                <li style="cursor:pointer;">
                    <a href="/" style="color:#d03737;">
                        <i class="fas fa-home"></i> Home /
                    </a>
                </li>
                <li class="breadcrumb-item" style="color:#d03737;">Supplier First-Stage Registration</li>
            </ul>
        </div>
    </nav>
    <div class="container">
        <p style="text-align: justify;">
            <b style="color:#d03737">Becoming a Supplier</b><br />
            "Novus ChemStrength is open to engaging with suppliers who are committed to upholding values such as trust, integrity, innovation, and sustainability. We view our relationship with suppliers as a collaborative and responsible partnership.
            If you are interested in becoming a supplier for Novus ChemStrength, we invite you to initiate the registration process provided below. Please note that this is the initial stage of registration, and depending on your input, there may be subsequent levels of information gathering, verifications, and d
        </p>



        <div class="form_container">
            <form form #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                <div class="row">


                    <div class="col-lg-4">

                        <label>CompanyName</label>
                        <input required minlength="3" maxlength="50" ngModel name="CompanyName" type="text" #name="ngModel" class="form-control" id="CompanyName">


                    </div>
                    <div class="col-lg-4">

                        <label>Profile Info</label>
                        <input required ngModel name="ProfileDescription" type="text" #number="ngModel" class="form-control" id="ProfileDescription">

                    </div>
                    <div class="col-lg-4">

                        <label>Address</label>
                        <input required ngModel name="Address" type="text" #email="ngModel" class="form-control" id="Address">

                    </div>



                    <div class="col-lg-4">

                        <label>State</label>
                        <input required minlength="3" maxlength="50" ngModel name="State" type="text" #name="ngModel" class="form-control" id="State">


                    </div>
                    <div class="col-lg-4">

                        <label>City</label>
                        <input required ngModel name="City" type="text" #number="ngModel" class="form-control" id="City">

                    </div>
                    <div class="col-lg-4">

                        <label>SalesContact</label>
                        <input required ngModel name="SalesContact" type="text" #email="ngModel" class="form-control" id="SalesContact">

                    </div>





                    <div class="col-lg-4">
                        <div class="form_control">
                            <label>Phone</label>
                            <input required minlength="3" maxlength="50" ngModel name="Phone" type="tel" #name="ngModel" class="form-control" id="Phone">
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form_control">
                            <label>Email</label>
                            <input required ngModel name="EMailID" type="text" #email="ngModel" class="form-control" id="EMailID">
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form_control">
                            <label>Pin</label>
                            <input required ngModel name="Pin" type="text" #email="ngModel" class="form-control" id="Pin">
                        </div>
                    </div>



                    <div class="col-lg-4">
                        <div class="form_control">
                            <label>Website Address</label>
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="WebsiteAddress">
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form_control">
                            <label>Products You Provide</label>
                            <input required ngModel name="ProductsYouProvide" type="text" #email="ngModel" class="form-control" id="ProductsYouProvide">
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form_control">
                            <label for="Upload">Upload List</label>
                            <input type="file" id="upload" name="upload" (change)="detectFiles($event)" accept=".pdf,.doc,.docx,.xls,.xlsx">
                        </div>
                    </div>


                    <div class="col-lg-12">
                        <div class="form_control">
                            <label>Remarks</label>
                            <textarea required ngModel name="Remarks" type="text" #selectedJobRole="ngModel" class="form-control" id="Remarks"></textarea>
                        </div>
                    </div>

                </div>

                    <div class="button_container">
                        <button type="submit" class="btn btn-primary" [class.disabled]="!contactForm.valid">Submit</button>
                    </div>

        
            </form>
           
        </div>
    </div>

    </div>
