import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SessiontimeoutService } from './Services/sessiontimeout.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private routerSubscription: any;

    constructor(private router: Router, private sessionTimeoutService: SessiontimeoutService) { }

    ngOnInit() {
        // Initialize session timeout handling
        

        // Subscribe to router events to handle navigation
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    ngOnDestroy() {
        // Unsubscribe from router events to prevent memory leaks
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }
}
