import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormsModule } from '@angular/forms'
import { ReactiveFormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { GeneralService } from '../../Services/generalservice.service';
import { NgxCaptureService } from 'ngx-capture';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-home-four',
  templateUrl: './home-four.component.html',
  styleUrls: ['./home-four.component.scss']
})
export class HomeFourComponent implements OnInit {

    EnquiryAry: any = [];
    formGroup: any;
    admin: any;
    TemplateId: any;
    MessageText: any;
    //ApiURL: any;
    contactForm: FormsModule;
    spinner: boolean = false;
    imgBase64: any = '';

    HomeUrl: any; 
    selectedFile: File;
    imagefile: string | ArrayBuffer;
    image: any;

    constructor(private captureService: NgxCaptureService, private viewportScroller: ViewportScroller, public generalService: GeneralService, private http: HttpClient, public fb: FormsModule, public Rm: ReactiveFormsModule) {

        
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);

    }

    ngOnInit() {


    }
    Name = '';
    Number = '';
    selectedJobRole = '';
    todayDate = '';
    Email = '';
    Message = '';
    @ViewChild('screen', { static: true }) screen: any;

    capture() {
         

        this.captureService.getImage(this.screen.nativeElement, true).subscribe(img => {
            console.log(img);
            this.imgBase64 = img
            this.save()
        })
    }

    DataURIToBlob(dataURI: string) { 
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
    }
    ip = "http://localhost:62161/Images"

    save() { 
        const file = this.DataURIToBlob(this.imgBase64)
        const formData = new FormData();
        formData.append('file', file, 'image.png')
        let url = "upload2.php"
        this.http.post(this.ip + url, formData).subscribe(data => {
            // this.generalService.PostData(url, UploadFile).then((data: any) => {


        })
    }

    onSubmit(form: any) { 
        this.EnquiryAry = [];
        this.EnquiryAry.push({
            Name: form.name,
            Number: form.number,
            selectedJobRole: form.selectedJobRole,
            todayDate: form.todayDate,
            Email: form.email,
            Message: form.message,
            Attachment: this.imagefile
        })
        var UploadFile = new FormData();
        UploadFile.append("Ticket1", JSON.stringify(this.EnquiryAry));       
        var url = "api/Azole/CarrerMail_ToCustomer"
        this.generalService.PostData(url, UploadFile).then((data: any) => { 

            Swal.fire(
                'SUCCESS',
                'Your Job Enquiry has been submitted successfully',
                'success'


            )
           // window.location.reload();
            // this.formGroup.reset();

        });

    }

    detectFiles(input) { 

        const reader = new FileReader();
        if (input.files.length) { 
            const file = input.files[0];
            reader.onload = () => { 
                this.imagefile = reader.result;
                this.image = this.imagefile;
            }
            reader.readAsDataURL(file);
            var selectedFile = file;
            var idxDot = selectedFile.name.lastIndexOf(".") + 1;
            var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == "doc" || extFile == "pdf") {
                
                var UploadFile = new FormData();
                UploadFile.append("PostedFile", file);
                var url = 'api/Azole/Upload_Attachment';
                this.generalService.PostData(url, UploadFile).then((data: any) => {
                    if (data != null) { 
                        this.imagefile = data;
                        this.image = data;
                    }
                });
            }
            else {
                alert("Only doc/jpg/jpeg, png files are allowed!");
            }

        }
    }
  

    onFileSelected(event: any) { 
        this.selectedFile = event.target.files[0] as File;
    }
    submit(form) {
        var name = form.name;
        console.log(name);

        var email = form.email;
        console.log(email);

        var number = form.number;
        console.log(number);

        var subject = form.subject;
        console.log(subject);

        var selectedJobRole = form.selectedJobRole;
        console.log(selectedJobRole);
        var todayDate = form.todayDate;
        console.log(todayDate);
        var message = form.message;
        console.log(message);
    }
    ngAfterViewInit() {
        document.getElementById('preloader').classList.add('hide');
    }
}
